<template>
  <div class="modalCode">
    <div class="modal-content">
      <span class="close-button" @click="closeModal">&times;</span>
      <h2 class="_text-18">Введите код подтверждения, отправленный вам на электронную почту</h2>
      <input
          type="text"
          class="confirmation-code-input _text-18"
          v-model="confirmationCode"
          placeholder="код поддтверждения"
      />
      <button class="confirm-button _text-18" @click="confirm">Подтвердить</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "modalCode",
  data: () => ({
    confirmationCode: ''
  }),
  methods: {
    /**
     Закрытие модального окна.
     */
    closeModal() {
      this.$store.commit("SET_SHOW_MODAL_CODE", false)
    },
    /**
      Подтверждение кода.
     */
    confirm() {
      // здесь будет логика подтверждения кода
    },
  },
}
</script>

<style scoped lang="scss">
.modalCode {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: $color_bg;
  padding: rem(20);
  border-radius: rem(5);
  max-width: rem(800);
  text-align: center;
  position: relative; /* Добавлено */
}

.close-button {
  position: absolute;
  top: rem(10);
  right: rem(10);
  font-size: rem(24);
  color: #ccc;
  cursor: pointer;
}

.modalCode h2 {
  padding: rem(20) 0;
}

.confirmation-code-input {
  width: 100%;
  padding: rem(10);
  margin-bottom: rem(10);
  border: rem(1) solid #ccc;
  border-radius: rem(5);
  text-align: center;
}

.confirm-button {
  padding: rem(10) rem(20);
  background-color: $color_bl;
  color: $color_bg;
  border: none;
  border-radius: rem(5);
  cursor: pointer;
}

.confirm-button:hover {
  background-color: #0056b3;
}
</style>
