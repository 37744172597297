<template>
  <div class="_wrapper">
    <HeaderComponent v-if="token !== ''"/>
    <router-view/>
    <NotificationBox :notifications="notifications" v-if="notifications.length > 0"/>
    <ModalCode v-if="showModalCode"/>
  </div>
</template>
<script>
import NotificationBox from "@/components/NotificationBox";
import {mapState} from "vuex";
import ModalCode from "@/components/UI/modalCode";
import HeaderComponent from "@/components/HeaderComponent";

export default {
  components: {HeaderComponent, ModalCode, NotificationBox},
  mounted() {
    /**
     * Обновление значения ширины экрана
     */
    this.$store.commit('SET_DISPLAY_WIDTH', window.innerWidth);
    window.addEventListener("resize", () => {
      this.$store.commit('SET_DISPLAY_WIDTH', window.innerWidth);
    });
  },
  computed: {
    ...mapState({notifications: "notifications"}),
    ...mapState({showModalCode: "showModalCode"}),
    ...mapState("client", {token: "token"})
  },
  watch: {
    /**
     * Привидение к исходному значению флага валидации
     */
    $route: function (newValue) {
      this.$store.commit("SET_IS_VALID", true)
    }
  }
}
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600&display=swap');

* {
  padding: 0;
  margin: 0;
  border: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

main {
  position: relative;
}

:focus,
:active {
  outline: none;
}

a:focus,
a:active {
  outline: none;
}

a, a:link, a:visited {
  text-decoration: none;
  display: inline-block;
}

a:hover {
  text-decoration: none;
}


aside, nav, footer, header, section, main {
  display: block;
}

h1, h2, h3, h4, h5, h6, p {
  font-size: inherit;
  font-weight: inherit;
}

ul, li {
  list-style: none;
}

img {
  vertical-align: top;
}

img, svg {
  max-width: 100%;
  height: auto;
}

address {
  font-style: normal;
}


input, textarea, button, select {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
}

input::-ms-clear {
  display: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none; // Yeah, yeah everybody write about it
}

input[type='number'],
input[type="number"]:hover,
input[type="number"]:focus {
  appearance: none;
  -moz-appearance: textfield;
}


._input-field {
  max-width: rem(600);
  width: 100%;
  height: rem(80);
  border: 1px solid #A9A9A9;
  border-radius: 5px;
  padding: 0 rem(20);
}

._inputError {
  border: 1px solid rgba(188, 9, 9, 1);
}

button, input[type="submit"] {
  display: inline-block;
  box-shadow: none;
  background: none;
  cursor: pointer;
}

input {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: rem(18);
  line-height: rem(25);
}

input:focus, input:active,
button:focus, button:active {
  outline: none;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

label {
  cursor: pointer;
  display: block;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: rem(18);
  line-height: rem(25);
  // margin-bottom: rem(10);
}

._mt {
  margin-top: rem(20);
}

legend {
  display: block;
}

._blue {
  color: $color_bl;
}

._grey {
  color: $color_grey;
}

._orange-bg {
  background-color: $color_orange;
}

._green-bg {
  background-color: $color_green;
}

._grey-bg {
  background-color: $color_grey;;
}

._title {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: rem(24);
  line-height: rem(29);
}

._sub-title {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: calc(20rem / 16 + (36 - 20) * ((100vw - 420rem / 16) / (1600 - 420)));
  line-height: calc(27rem / 16 + (49 - 27) * ((100vw - 420rem / 16) / (1600 - 420)));
}

._text-12 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: rem(12);
  line-height: rem(16);
}

._text-18 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: rem(18);
  line-height: rem(25);
}

._text-20 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: calc(16rem / 16 + (20 - 16) * ((100vw - 420rem / 16) / (1600 - 420)));
  line-height: calc(22rem / 16 + (27 - 22) * ((100vw - 420rem / 16) / (1600 - 420)));
  color: $color-base
}

._text-24 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: calc(18rem / 16 + (24 - 18) * ((100vw - 420rem / 16) / (1600 - 420)));
  line-height: calc(25rem / 16 + (33 - 25) * ((100vw - 420rem / 16) / (1600 - 420)));;
}

._text-24_bold {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: calc(18rem / 16 + (24 - 18) * ((100vw - 420rem / 16) / (1600 - 420)));
  line-height: calc(25rem / 16 + (33 - 25) * ((100vw - 420rem / 16) / (1600 - 420)));
}

._link {
  display: block;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: calc(16rem / 16 + (20 - 16) * ((100vw - 420rem / 16) / (1600 - 420)));
  line-height: calc(22rem / 16 + (27 - 22) * ((100vw - 420rem / 16) / (1600 - 420)));
  color: $color_bl;
  text-align: center;
}

._text-error {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: rem(12);
  color: rgba(206, 10, 10, 0.96);
}

._wrapper {
  padding: 0 2.5%;
}

._mainFormInput {
  height: 100vh;
  max-width: rem(600);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;

  ._title {
    padding-bottom: rem(40);
  }

  form, button {
    margin-top: calc(20rem / 16 + (40 - 20) * ((100vw - 420rem / 16) / (1600 - 420)));
  }

  form:nth-child(2), {
    margin-top: 0;
  }
}

._registration-form {
  max-width: rem(600);
  margin: 0 auto;

  ._title {
    padding-bottom: rem(55);
  }

  form {
    margin-top: calc(17rem / 16 + (29 - 17) * ((100vw - 420rem / 16) / (1600 - 420)));
  }

  p {
    padding-top: calc(10rem / 16 + (30 - 10) * ((100vw - 420rem / 16) / (1600 - 420)));
  }

  .checkbox {
    display: flex;
    gap: calc(4rem / 16 + (8 - 4) * ((100vw - 420rem / 16) / (1600 - 420)));
    margin: calc(13rem / 16 + (30 - 13) * ((100vw - 420rem / 16) / (1600 - 420))) 0 calc(20rem / 16 + (30 - 20) * ((100vw - 420rem / 16) / (1600 - 420)));
  }

  .house-info {
    margin-top: calc(17rem / 16 + (29 - 17) * ((100vw - 420rem / 16) / (1600 - 420)));
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: calc(21rem / 16 + (60 - 21) * ((100vw - 420rem / 16) / (1600 - 420)));
    row-gap: calc(17rem / 16 + (46 - 17) * ((100vw - 420rem / 16) / (1600 - 420)));

    form {
      margin: 0;

      input {
        text-align: center;
      }
    }
  }
}

._input {
  position: relative;
}

._action-button {
  margin-top: rem(20);
  text-align: center;
}

._active {
  color: $color_bl;
}

@media (min-width: em(1600, 16)) {
  ._wrapper {
    padding: 0 2.5%;
  }
  ._text-20, ._link {
    font-size: rem(20);
    line-height: rem(27);
  }
  ._registration-form {
    form {
      margin-top: rem(29);
    }

    .checkbox {
      display: flex;
      align-items: center;
      gap: rem(8);
      margin: rem(30) 0;
    }

    .house-info {
      margin-top: rem(29);
      column-gap: rem(100);
      row-gap: rem(46);

      form {
        margin-top: 0;
      }
    }

    p {
      padding-top: rem(30);
    }
  }
}
</style>
