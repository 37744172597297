import {createRouter, createWebHistory} from 'vue-router'
import store from "@/store";

const routes = [
    {
        path: '/',
        name: 'ProfileView',
        component: () => import('@/views/ProfileView')
    },
    {
        path: '/authorizationForm',
        name: 'authorizationForm',
        component: () => import('@/views/AuthorizationView')
    },
    {
        path: '/recoveryPassword',
        name: 'recoveryPassword',
        component: () => import('@/views/RecoveryForm')
    },
    {
        path: '/registrationForm',
        name: 'registrationForm',
        component: () => import('@/views/RegistrationForm')
    },
    {
        path: '/orderInfo',
        name: 'OrderInfo',
        component: () => import('@/views/OrderInfo')
    },
    {
        path: '/ordering',
        name: 'OrderingView',
        component: () => import('@/views/OrderingView')
    },
    {
        path: '/thanks',
        name: 'ThanksView',
        component: () => import('@/views/ThanksView')
    },
    {
        path: "/confirmationPage",
        name: "ConfirmationPage",
        component: () => import('@/views/ConfirmationPage')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})
router.beforeEach((to, from, next) => {
    store.dispatch('client/checkingToken');
    if (to.path === '/registrationForm' || to.path === '/recoveryPassword') {
        next();
        return;
    }
    if (to.path !== '/authorizationForm' && sessionStorage.getItem('msoff_get_token') === null) {
        next('/authorizationForm');
    } else {
        next();
    }
});
export default router
