import axios from "axios";

export const Services = {
    state: () => ({
        parentCategory: [],
        categories: [],
        subCategories: [],
        services: [],
        ordersList: localStorage.getItem('ordersList') !== null ? JSON.parse(localStorage.getItem('ordersList')) : [],
        activeMenu: "",
        activeSubMenu: "",
        archive: []
    }),
    getters: {
        total(state) {
            return state.ordersList.reduce((price, item) => {
                return price += parseInt(item.cost);
            }, 0);
        },
        quantityOrder(state) {
            return state.ordersList.length
        },
        quantitySuffix(getters) {
            const lastDigit = getters.quantityOrder % 10; // Получаем последнюю цифру в значении quantityOrder
            const lastTwoDigits = getters.quantityOrder % 100; // Получаем последние две цифры в значении quantityOrder

            if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
                // Если последние две цифры находятся в диапазоне от 11 до 19 (например, 11, 12, 13...), используем "услуг"
                return 'услуг';
            } else if (lastDigit === 1) {
                // Если последняя цифра равна 1, используем "услуга"
                return 'услуга';
            } else if (lastDigit >= 2 && lastDigit <= 4) {
                // Если последняя цифра находится в диапазоне от 2 до 4, используем "услуги"
                return 'услуги';
            } else {
                // Во всех остальных случаях используем "услуг"
                return 'услуг';
            }
        }
    },
    mutations: {
        SET_PARENT_CATEGORY(state, obj) {
            state.parentCategory = obj
        },
        SET_CATEGORIES(state, obj) {
            state.categories = obj;
        },
        SET_SUB_CATEGORIES(state, obj) {
            state.subCategories = obj
        },
        SET_ACTIVE_MENU(state, value) {
            state.activeMenu = value
        },
        SET_ACTIVE_SUB_MENU(state, value) {
            state.activeSubMenu = value
        },
        SET_SERVICES(state, obj) {
            state.services = obj
        },
        SET_ORDERS_LIST(state, obj) {
            state.ordersList.push(obj)
            localStorage.setItem("ordersList", JSON.stringify(state.ordersList));
        },
        REMOVE_IN_ORDERS_LIST(state, item) {
            state.ordersList = state.ordersList.filter(elem => elem.id !== item);
            localStorage.setItem("ordersList", JSON.stringify(state.ordersList));
        },
        SET_ARCHIVE(state, history) {
            state.archive = history;
        }
    },
    actions: {
        async getParentCategories({commit, rootGetters}) {
            try {
                const headers = rootGetters['client/headers'];
                const response = await axios.get("/api/services/getParentCategories", headers)
                commit("SET_PARENT_CATEGORY", response.data["parent-categories"])
            } catch (error) {
                console.error(error)
            }
        },
        async getCategories({commit, rootGetters, dispatch}, {id, tumbler}) {
            try {
                const headers = rootGetters['client/headers'];
                const response = await axios.post("/api/services/getCategories", {id: id}, headers)
                commit("SET_CATEGORIES", response.data["categories"])
                dispatch("getSubCategories", {
                    id: response.data["categories"][0].id,
                    current: response.data["categories"][0].name
                })
                if (!tumbler) {
                    commit("SET_ACTIVE_MENU", response.data["categories"][0].name)
                }
            } catch (error) {
                console.error(error)
            }
        },
        async getSubCategories({state, commit, rootGetters, dispatch}, {id}) {
            try {
                const headers = rootGetters['client/headers'];
                const response = await axios.post("/api/services/getSubCategories", {id: id}, headers)
                commit("SET_SUB_CATEGORIES", response.data["sub-categories"])
                commit("SET_ACTIVE_SUB_MENU", response.data["sub-categories"][0].name)
                dispatch("getServices", {
                    id: response.data["sub-categories"][0].id
                });
            } catch (error) {
                console.error(error)
            }
        },
        async getServices({commit, rootGetters}, {id, current}) {
            try {
                const headers = rootGetters['client/headers'];
                const response = await axios.post("/api/services/getServices", {id: id}, headers)
                commit("SET_SERVICES", response.data["services"])
                if (current) {
                    commit("SET_ACTIVE_SUB_MENU", current)
                }
            } catch (error) {
                console.error(error)
            }
        },
        async addService({state, rootGetters, commit}, comment) {
            function stripTags(value) {
                const div = document.createElement('div');
                div.innerHTML = value;
                return div.textContent || div.innerText || '';
            }

            try {
                const headers = rootGetters['client/headers'];
                const response = await axios.post("/api/requests/add", {
                    usl: state.ordersList,
                    comment: stripTags(comment)
                }, headers)
                commit("REMOVE_IN_ORDERS_LIST", state.ordersList[0].id)
            } catch (error) {
                console.error(error)
            }
        },
        async search({commit, rootGetters}, row) {
            const headers = rootGetters['client/headers'];
            try {
                const response = await axios.post("/api/services/search", {
                    row: row
                }, headers)
                commit("SET_SERVICES", response.data.search)
            } catch (error) {
                console.error(error)
            }
        },
        async getArchive({commit, rootGetters}, archive) {
            const headers = rootGetters['client/headers'];
            try {
                const response = await axios.get(`/api/requests/all?archive=${archive}`, headers)
                commit("SET_ARCHIVE", response.data.result)
            } catch (error) {
                console.error(error)
            }
        }
    },
    namespaced: true
}