import {createStore} from 'vuex';
import {Client} from "@/store/client";
import {Services} from "@/store/services";

export default createStore({
    state: {
        display_width: 0,
        notifications: [],
        notificationId: 0, // Добавлено свойство notificationId
        showModalCode: false,
        render: "profile",
        dataAfterValidation: {},
        isValid: true
    },
    getters: {},
    mutations: {
        SET_DISPLAY_WIDTH(state, innerWidth) {
            state.display_width = innerWidth;
        },
        SET_NOTIFICATIONS(state, { status, message }) {
            const id = state.notificationId++;

            // Проверяем, существует ли уже ошибка с таким же сообщением
            const existingNotification = state.notifications.find(notification => notification.message === message);

            if (!existingNotification) {
                state.notifications.push({ id, status, message, remove: false });

                if (state.notifications.length === 1) {
                    const removeNotificationsInterval = setInterval(() => {
                        const notification = state.notifications[0];
                        notification.remove = true; // Устанавливаем свойство remove в true

                        setTimeout(() => {
                            state.notifications.shift(); // Удаляем первое уведомление
                            if (state.notifications.length === 0) {
                                clearInterval(removeNotificationsInterval); // Останавливаем интервал, если все уведомления удалены
                            }
                        }, 400); // Задержка перед удалением уведомления
                    }, 1000); // Задержка между удалением каждого уведомления
                }
            }
        },
        SET_SHOW_MODAL_CODE(state, boolean) {
            state.showModalCode = boolean;
        },
        SET_RENDER(state, {route, router, value}) {
            if (route.path !== "/registrationForm") {
                router.push("/");
            }
            state.render = value;
        },
        SET_DATA_ITEM_AFTER_VALIDATION(state, obj) {
            state.dataAfterValidation[obj.key] = obj.value
        },
        SET_IS_VALID(state, bool) {
            state.isValid = bool
        }
    },
    actions: {
        validateField({ commit, dispatch }, { value, type }) {
            /**
             * Удаляет HTML-теги из строки и возвращает очищенный текст
             * @param {string} value - Строка с HTML-тегами
             * @returns {string} - Очищенный текст без HTML-тегов
             */
            function stripTags(value) {
                const div = document.createElement('div');
                div.innerHTML = value;
                return div.textContent || div.innerText || '';
            }
            let fieldName = "";
            switch (type) {
                case "name":
                    fieldName = "Имя"
                    break;
                case "last_name":
                    fieldName = "Фамилия"
                    break;
                case "second_name":
                    fieldName = "Отчество"
                    break;
                case "email":
                    fieldName = "Email"
                    break;
                case "phone":
                    fieldName = "Телефон"
                    break;
                case "password":
                    fieldName = "Пароль"
                    break;
                case "oldPassword":
                    fieldName = "Старый пароль"
                    break;
                case "city":
                    fieldName = "Город"
                    break;
                case "street":
                    fieldName = "Улица"
                    break;
                case "flat":
                    fieldName = "Квартира"
                    break;
                case "floor":
                    fieldName = "Этаж"
                    break;
                case "entrance":
                    fieldName = "Подъезд"
                    break;
                case "house":
                    fieldName = "Дом"
                    break;
            }
            // Получите доступ к $store внутри функции, используя commit
            const input = document.querySelector(`#${type}`);
            // Проверка на заполненность поля
            if (!value && !(type === "korp" || type === "flat" || type === "floor" || type === "entrance" || type === "second_name")) {
                const errorMessage = `Поле "${fieldName}" обязательно для заполнения`;
                commit('SET_NOTIFICATIONS', {
                    status: 'error',
                    message: errorMessage
                });
                input.classList.add("_inputError");
                commit("SET_IS_VALID", false)
                return;
            }
            // Дополнительные проверки в зависимости от типа поля
            switch (type) {
                case "name":
                case "last_name":
                case "second_name": {
                    // Проверка наличия цифр
                    if (/\d/.test(value)) {
                        const errorMessage = `Поле "${fieldName}" не должно содержать цифр`;
                        commit('SET_NOTIFICATIONS', {
                            status: 'error',
                            message: errorMessage
                        });
                        input.classList.add("_inputError");
                        commit("SET_IS_VALID", false)
                        return;
                    }
                    // Форматирование первой буквы
                    const cleanedValue = stripTags(value.trim());
                    input.classList.remove("_inputError");
                    commit("SET_DATA_ITEM_AFTER_VALIDATION", {
                        key: type,
                        value: cleanedValue.charAt(0).toUpperCase() + cleanedValue.slice(1).toLowerCase()
                    })
                    break;
                }
                case "email": {
                    // Проверка формата email
                    if (!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(value)) {
                        commit('SET_NOTIFICATIONS', {
                            status: 'error',
                            message: 'Поле "Email" должно быть в формате example@example.com'
                        });
                        input.classList.add("_inputError");
                        commit("SET_IS_VALID", false)
                        return;
                    }
                    input.classList.remove("_inputError");
                    const result = stripTags(value);
                    commit("SET_DATA_ITEM_AFTER_VALIDATION", {
                        key: type,
                        value: result.trim().toLowerCase()
                    })
                    break;
                }
                case "phone": {
                    const cleanedValue = stripTags(value).replace(/[\s()-]/g, "");
                    // Проверка на заполненность поля телефона
                    if (!cleanedValue) {
                        commit('SET_NOTIFICATIONS', {
                            status: 'error',
                            message: 'Поле "Телефон" обязательно для заполнения'
                        });
                        input.classList.add("_inputError");
                        commit("SET_IS_VALID", false)
                        return;
                    }
                    // Проверка формата телефона
                    if (!/^(\+?7|8)\d{10}$/.test(cleanedValue)) {
                        commit('SET_NOTIFICATIONS', {
                            status: 'error',
                            message: 'Некорректный номер телефона'
                        });
                        input.classList.add("_inputError");
                        commit("SET_IS_VALID", false)
                        return;
                    }
                    input.classList.remove("_inputError");
                    // Форматирование телефона
                    commit("SET_DATA_ITEM_AFTER_VALIDATION", {
                        key: type,
                        value: cleanedValue.replace(/^(\+?7|8)(\d{3})(\d{3})(\d{2})(\d{2})$/, "+7($2)$3-$4-$5")
                    })
                    break;
                }
                case "city":
                case "street": {
                    const stripped = stripTags(value);
                    let formatted;
                    if (stripped.includes('-')) {
                        // Город указан через дефис
                        formatted = stripped
                            .trim()
                            .split('-')
                            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                            .join('-');
                    } else if (stripped.includes(' ')) {
                        // Город указан через пробел
                        formatted = stripped
                            .trim()
                            .split(' ')
                            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                            .join(' ');
                    } else {
                        // Город не содержит ни дефиса, ни пробела
                        formatted = stripped.charAt(0).toUpperCase() + stripped.slice(1).toLowerCase();
                    }
                    input.classList.remove("_inputError");
                    commit("SET_DATA_ITEM_AFTER_VALIDATION", {
                        key: type,
                        value: formatted
                    })
                    break;
                }
                case "house": {
                    if (!/^(\d+[а-яА-Я]?)$/.test(value)){
                        commit('SET_NOTIFICATIONS', {
                            status: 'error',
                            message: 'Поле "Дом" указано в неправильном формате'
                        });
                        input.classList.add("_inputError");
                    }else {
                        input.classList.remove("_inputError");
                        commit("SET_DATA_ITEM_AFTER_VALIDATION", {
                            key: type,
                            value: stripTags(value)
                        })
                    }
                    break;
                }
                case "korp": {
                    if (value.trim()) {
                            // Применение функции stripTags к полю korp
                            const strippedKorp = stripTags(value);
                            input.classList.remove("_inputError");
                            commit("SET_DATA_ITEM_AFTER_VALIDATION", {
                                key: type,
                                value: strippedKorp.trim()
                            })
                    }
                    break;
                }
                case "entrance":
                case "floor":
                case "flat": {
                    if (value) {
                        // Проверка на наличие букв в поле entrance
                        if (!/^\d+$/.test(value)) {
                            commit('SET_NOTIFICATIONS', {
                                status: 'error',
                                message: `Поле "${fieldName}" не должно содержать буквы`
                            });
                            input.classList.add("_inputError");
                            commit("SET_IS_VALID", false)
                            return;
                        } else {
                            // Применение функции stripTags к полю entrance
                            const strippedEntrance = stripTags(value);
                            input.classList.remove("_inputError");
                            commit("SET_DATA_ITEM_AFTER_VALIDATION", {
                                key: type,
                                value: strippedEntrance.trim()
                            })
                        }
                    }
                    break;
                }
                case "password":
                case "oldPassword": {
                    const strippedPassword = stripTags(value);
                    if (!value.trim()) {
                        commit('SET_NOTIFICATIONS', {
                            status: 'error',
                            message: `Заполните поле ${fieldName}`
                        });
                        input.classList.add("_inputError");
                        commit("SET_IS_VALID", false)
                        return;
                    } else if (!/[a-z]/.test(strippedPassword) || !/[A-Z]/.test(strippedPassword)) {
                        commit('SET_NOTIFICATIONS', {
                            status: 'error',
                            message: 'Пароль должен содержать хотя бы одну строчную и одну заглавную букву'
                        });
                        input.classList.add("_inputError");
                        commit("SET_IS_VALID", false)
                        return;
                    } else if (strippedPassword.length < 6) {
                        commit('SET_NOTIFICATIONS', {
                            status: 'error',
                            message: 'Пароль должен содержать хотя бы одну строчную и одну заглавную букву'
                        });
                        input.classList.add("_inputError");
                        commit("SET_IS_VALID", false)
                        return;
                    } else {
                        input.classList.remove("_inputError");
                        commit("SET_DATA_ITEM_AFTER_VALIDATION", {
                            key: type,
                            value: value.trim()
                        })
                    }
                    break;
                }
                default:
                    break;
            }
        }
    },
    modules: {
        client: Client,
        services: Services
    }
});
