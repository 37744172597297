<template>
  <div class="notification-container">
    <div
        v-for="(notification, index) in notifications"
        :key="notification.id"
        :class="['notification', notification.status, { 'remove': notification.remove }, `delay-${index}`]"
        :style="{ animationDelay: `${index * 0.4}s` }"
    >
      <p>{{ notification.message }}</p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    notifications: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
  },
  computed: {
    ...mapState({ notificationClass: "notifications" }),
  },
};
</script>

<style scoped lang="scss">
.notification-container {
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 999;
  padding: rem(40);
}

.notification {
  position: relative;
  padding: rem(10);
  margin-bottom: rem(40);
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  animation: slide-up 0.3s ease-in-out forwards;
  opacity: 0;
}

.notification.success {
  background-color: #4CAF50;
  color: #fff;
}

.notification.warning {
  background-color: #FFC107;
}

.notification.error {
  background-color: #FF5722;
  color: #fff;
}

.notification.remove {
  animation: fade-out 0.4s ease-in-out forwards;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

</style>
