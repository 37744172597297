<template>
  <header>
    <nav>
      <li
          class="_text-24"
          :class="{'_blue':render === 'service'}"
          @click="changeRender( 'service')"
      >
        Услуги
      </li>
      <li
          class="_text-24"
          :class="{'_blue':render === 'in-work'}"
          @click="changeRender('in-work',0)"
      >
        В работе
      </li>
      <li
          class="_text-24"
          :class="{'_blue':render === 'archive'}"
          @click="changeRender('archive', 1)"
      >
        Архив
      </li>
    </nav>
    <div
        class="my-lk"
        @click="changeRender('profile')"
    >
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10 12C15.5228 12 20 14.0893 20 16.6667V20H0V16.6667C0 14.0893 4.47715 12 10 12ZM18.6667 16.6667C18.6667 14.8257 14.7864 13.3333 10 13.3333C5.21353 13.3333 1.33333 14.8257 1.33333 16.6667V18.6667H18.6667V16.6667ZM10 0C12.5773 0 14.6667 2.08933 14.6667 4.66667C14.6667 7.24399 12.5773 9.33333 10 9.33333C7.42267 9.33333 5.33333 7.24399 5.33333 4.66667C5.33333 2.08933 7.42267 0 10 0ZM10 1.33333C8.15907 1.33333 6.66667 2.82572 6.66667 4.66667C6.66667 6.50762 8.15907 7.99999 10 7.99999C11.8409 7.99999 13.3333 6.50762 13.3333 4.66667C13.3333 2.82572 11.8409 1.33333 10 1.33333Z"
            fill="#0195C4"/>
      </svg>
      <p class="_blue _text-24">
        {{ initials }}
      </p>
    </div>
  </header>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "HeaderComponent",

  computed: {
    ...mapState({render: "render"}),
    ...mapState("client", {client: "client"}),


    /**
     * Получения инициалов пользователя.
     * @returns {string} Инициалы пользователя в формате "Фамилия И. О.".
     */
      initials() {
        const { last_name, name, second_name } = this.client.address;

        // Форматирование фамилии пользователя
        const formattedLastName = last_name.charAt(0).toUpperCase() + last_name.slice(1).toLowerCase();
        // Форматирование имени пользователя
        const formattedFirstName = name.charAt(0).toUpperCase();
        // Форматирование отчества пользователя
        const formattedSecondName = second_name.charAt(0).toUpperCase();

        // Возвращение инициалов пользователя
        return `${formattedLastName} ${formattedFirstName}. ${formattedSecondName}.`;
      }
  },
  methods: {
    /**
     * Изменение отображаемого контента на странице, вывод истории заявок.
     * @param {string} value - Значение, определяющее тип контента для отображения.
     * @param {string} archive - Значение, указывающее на тип архивного контента в работе или архив.
     */
    changeRender(value, archive) {
      // Если значение не равно "service", получаем архивный контент
      if (value !== "service") {
        this.$store.dispatch("services/getArchive", archive)
      }
      // Изменяем отображаемый контент
      this.$store.commit("SET_RENDER", {
        route: this.$route,
        router: this.$router,
        value: value
      })
    }
  }
}
</script>

<style scoped lang="scss">

header {
  display: flex;
  justify-content: space-between;
  padding: calc(20rem / 16 + (50 - 20) * ((100vw - 420rem / 16) / (1600 - 420))) 0;
  border-bottom: 1px solid #C3C3C3;

  .my-lk, nav {
    display: flex;
    align-items: center;
  }

  nav {
    gap: calc(20rem / 16 + (50 - 20) * ((100vw - 420rem / 16) / (1600 - 420)));

    li {
      cursor: pointer;
    }
  }

  .my-lk {
    gap: rem(10);
    cursor: pointer;
  }

}

@media (min-width: em(1600, 16)) {
  header {
    padding: rem(50) 0;
  }
}

@media (max-width: em(760, 16)) {
  header {
    flex-direction: column;
    row-gap: rem(20);

    .my-lk {
      order: 1;
      justify-content: flex-end;
    }

    nav {
      order: 2;
    }
  }
}
</style>