import axios from "axios";


export const Client = {
    state: () => ({
        token: sessionStorage.getItem('msoff_get_token') !== null ? sessionStorage.getItem('msoff_get_token') : '',
        client: sessionStorage.getItem('msoff_get_client') !== null ? JSON.parse(sessionStorage.getItem('msoff_get_client')) : {},
        incorrectPassword: ''
    }),
    getters: {
        /**
         * Геттер headers возвращает объект с заголовком Authorization для авторизации запросов.
         * @param {Object} state - Состояние модуля.
         * @returns {Object} - Объект с заголовком Authorization.
         */
        headers: function (state) {
            return {
                headers: {
                    Authorization: `Bearer ${state.token}`
                }
            }
        }
    },
    mutations: {
        /**
         * Установка токена авторизации в состояние и хранилище сеанса.
         * @param {Object} state - Состояние модуля.
         * @param {string} token - Токен авторизации.
         */
        SET_TOKEN(state, token) {
            state.token = token;

            if (token === '') {
                sessionStorage.removeItem('msoff_get_token')
            } else {
                sessionStorage.setItem('msoff_get_token', token)
            }
        },
        /**
         * Установка клиента авторизации в состояние и хранилище сеанса.
         * @param {Object} state - Состояние модуля.
         * @param {Object} obj - Объект клиента.
         */
        SET_CLIENT(state, obj) {
            state.client = obj
            sessionStorage.setItem("msoff_get_client", JSON.stringify(state.client))
        },
        /**
         * Установка сообщения об ошибке неверного пароля в состояние модуля.
         * @param {Object} state - Состояние модуля.
         * @param {string} message - Сообщение об ошибке неверного пароля.
         */
        SET_INCORRECT_PASSWORD(state, message) {
            state.incorrectPassword = message;
        },
        CHANGE_CLIENT(state, obj) {
            state.client.address = obj
            sessionStorage.setItem("msoff_get_client", JSON.stringify(state.client))
        }
    },
    actions: {
        async fetchToken({state, commit}, {email, password, router}) {
            try {
                // Отправка данных формы на сервер
                const response = await axios.post('/api/auth/getToken', {
                    email: email,
                    password: password
                })
                if (response.data.code === 200) {
                    commit("SET_TOKEN", response.data.access_token)
                    commit("SET_CLIENT", {
                        name_data: response.data.name_data,
                        address: response.data.address
                    });
                    commit("SET_INCORRECT_PASSWORD", "")
                    router.push('/')
                }
                if (response.data.code === 401) {
                    commit("SET_NOTIFICATIONS", {status: "error", message: response.data.message}, {root: true})
                    commit("SET_INCORRECT_PASSWORD", response.data.message)
                }
            } catch (error) {
                commit("SET_NOTIFICATIONS", {status: "error", message: error.message}, {root: true})
            }
        },
        // проверка токена
        checkingToken({state, commit}) {
            if (state.token !== '') {
                try {

                    axios.get('/api/auth/validateToken', {
                        headers: {
                            Authorization: `Bearer ${state.token}`
                        }
                    }).then((response) => {
                        if (response.data.code !== 200) {
                            commit('SET_TOKEN', '')
                        }
                    })
                } catch (error) {
                    console.error(error)
                }
            }
        },

        //процедура регистрации
        async sendRegistrationForm({state, rootState, commit}, router) {
            if (rootState.isValid) {
                try {
                    const response = await axios.post('/api/auth/register', {
                        email: rootState.dataAfterValidation.email,
                        name: rootState.dataAfterValidation.name,
                        phone: rootState.dataAfterValidation.phone,
                        password: rootState.dataAfterValidation.password,
                        last_name: rootState.dataAfterValidation.last_name,
                        second_name: rootState.dataAfterValidation.second_name,
                        city: rootState.dataAfterValidation.city,
                        street: rootState.dataAfterValidation.street,
                        house: rootState.dataAfterValidation.house,
                        korp: rootState.dataAfterValidation.korp,
                        entrance: rootState.dataAfterValidation.entrance,
                        floor: rootState.dataAfterValidation.floor,
                        flat: rootState.dataAfterValidation.flat
                    });
                    if (response.data.result) {
                        router.push("/authorizationForm");
                        commit("SET_NOTIFICATIONS", {
                            status: "success",
                            message: "Регистрация успешна"
                        }, {root: true})
                    }

                } catch (error) {
                    console.error(error);
                    commit("SET_NOTIFICATIONS", {status: "error", message: error.response.data.message}, {root: true})

                }
            }
        },

        // Изменение пользовательских данных

        //изменение данных о ФИО и адресе

        async changeUserInitialAndAddress({state, rootState, getters, commit}) {
            try {
                const response = await axios.post("/api/citizens/changeInfo", {
                    name: rootState.dataAfterValidation.name,
                    last_name: rootState.dataAfterValidation.last_name,
                    second_name: rootState.dataAfterValidation.second_name,
                    city: rootState.dataAfterValidation.city,
                    street: rootState.dataAfterValidation.street,
                    house: rootState.dataAfterValidation.house,
                    korp: rootState.dataAfterValidation.korp,
                    entrance: rootState.dataAfterValidation.entrance,
                    floor: rootState.dataAfterValidation.floor,
                    flat: rootState.dataAfterValidation.flat
                }, getters.headers)

                commit("CHANGE_CLIENT", response.data.address)
                commit("SET_TOKEN", response.data.access_token)
                commit("SET_NOTIFICATIONS", {
                    status: "success",
                    message: "Новые данные сохранены"
                }, {root: true})
            } catch (error) {
                console.error(error);
                commit("SET_NOTIFICATIONS", {
                    status: "error",
                    message: error.response.data.message
                }, {root: true})
            }
        },

        //запрос на смену email, phone

        async requestChangeContact({state, rootState, getters, commit}, {type, value}) {
            if (rootState.isValid) {
                try {
                    let obj = {}
                    let link = ""
                    switch (type) {
                        case "email":
                            obj = {
                                email: rootState.dataAfterValidation.email
                            }
                            link = "/api/citizens/requestChangeEmail"
                            break;
                        case "phone":
                            obj = {
                                phone: rootState.dataAfterValidation.phone
                            }
                            link = "/api/citizens/requestChangePhone"
                            break;
                    }
                    const response = await axios.post(link, obj, getters.headers)
                    if (response.data.code === 200) {
                        commit("SET_NOTIFICATIONS", {
                            status: "success",
                            message: "На вашу почту отправлено письмо для активации"
                        }, {root: true})
                    }
                } catch (error) {
                    console.error(error);
                    commit("SET_NOTIFICATIONS", {status: "error", message: error.response.data.message}, {root: true})
                }
            }
        },
        //изменение пароля
        async changePassword({state, getters, commit}, {oldPassword, newPassword}) {
            try {
                const response = await axios.post("/api/auth/changePassword", {
                    old_password: oldPassword,
                    password: newPassword
                }, getters.headers)
                if (response.data.code === 200) {
                    commit("SET_NOTIFICATIONS", {
                        status: "success",
                        message: "Пароль успешно изменён"
                    }, {root: true})
                }
            } catch (error) {
                console.error(error);
                commit("SET_NOTIFICATIONS", {status: "error", message: error.response.data.message}, {root: true})
            }
        },

        // восстановление пароля

        async recoveryPassword({state, getters, commit}, {router, email}) {
            try {
                const response = await axios.post("/api/auth/restorePassword", {
                    email: email
                })
                if (response.data.code === 200) {
                    router.push("/authorizationForm")
                    commit("SET_NOTIFICATIONS", {
                        status: "success",
                        message: response.data.message
                    }, {root: true})
                }
            } catch (error) {
                console.error(error);
                commit("SET_NOTIFICATIONS", {status: "error", message: "Пользователь не найден"}, {root: true})
            }
        }
    },
    namespaced: true
};
